import React from 'react'
import {
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  mobilePromotion,
  mobilePromotionFraser,
  promotionsFraser,
  promotions,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFImage src={mobilePromotion} w="100%" alt="Nene Chicken Map" />
      </MobileScreen>
      <DefaultScreen>
        <CFImage src={promotions} w="100%" alt="Nene Chicken Map" />
      </DefaultScreen>
    </CFView>
  )
}

import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  locationMapKingsway,
  locationMapDavie,
  locationMapPoco,
  locationMapToronto,
  mobileMapDavie,
  mobileMapKingsway,
  mobileMapPoco,
  mobileMapToronto,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let locationLink = ''
        let mapImageMobile = null
        let mapImage = null
        if (locationId === 'pjYRQQGuWMiO2stx5IRf') {
          locationLink = 'https://goo.gl/maps/GDCwQAKJjcR7bp1o7'
          mapImage = locationMapDavie
          mapImageMobile = mobileMapDavie
        } else if (locationId === 'un6UqV0P5yjoZhQm7lRC') {
          locationLink = 'https://goo.gl/maps/FMVj4Nr7xUnuTHmS7'
          mapImage = locationMapToronto
          mapImageMobile = mobileMapToronto
        } else if (locationId === '6IHCp7Ls99WS8F3BVf22') {
          locationLink = 'https://maps.app.goo.gl/XKokdY8pDxS1NJ9S9'
          mapImage = locationMapPoco
          mapImageMobile = mobileMapPoco
        } else {
          locationLink = 'https://goo.gl/maps/hhmVyMtT8oS1bM2y8'
          mapImage = locationMapKingsway
          mapImageMobile = mobileMapKingsway
        }
        return (
          <CFView>
            <MobileScreen>
              <CFLink href={locationLink}>
                <CFImage src={mapImageMobile} w="100%" alt="Nene Chicken Map" />
              </CFLink>
            </MobileScreen>
            <DefaultScreen>
              <CFLink href={locationLink}>
                <CFImage src={mapImage} w="100%" alt="Nene Chicken Map" />
              </CFLink>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}

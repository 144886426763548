export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/yelp.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/facebook.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/zomato.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1585671918/cafesaladedefruits/saladedefruits_instagram.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1591377852/general/cflogo_black.png'
export const about2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661118563/nenechicken/about2.jpg'
export const about =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661118562/nenechicken/about.jpg'
export const contactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1663186452/nenechicken/footer2.jpg'
export const foodGallery1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661978586/nenechicken/gallery1.jpg'
export const foodGallery2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661978587/nenechicken/gallery2.jpg'
export const foodGalleryFraser =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874357/samuraisushi/foodGalleryFraser.png'
export const hero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661118621/nenechicken/hero.jpg'
export const homebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1618874362/samuraisushi/homebg.jpg'
export const locationMapKingsway =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661118562/nenechicken/map.jpg'
export const locationMapDavie =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661123848/nenechicken/mapDavie.jpg'
export const locationMapPoco =
  'https://d23vvnyml6sr67.cloudfront.net/mapPoco.webp'
export const locationMapToronto =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1670263298/nenechicken/mapToronto.jpg'
export const logo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661118561/nenechicken/logo.png'
export const mobileAbout =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661121677/nenechicken/mobileAbout.jpg'
export const mobileAbout2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661122182/nenechicken/mobileAbout2.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661122980/nenechicken/mobileFooter.jpg'
export const mobileGallery1 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661978587/nenechicken/mobileGallery1.jpg'
export const mobileGallery2 =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661978591/nenechicken/mobileGallery2.jpg'
export const mobileHero =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661120457/nenechicken/mobileHero.jpg'
export const mobileMapKingsway =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661123651/nenechicken/mobileMap.jpg'
export const mobileMapDavie =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661123976/nenechicken/mobileMapDavie.jpg'
export const mobileMapPoco =
  'https://d23vvnyml6sr67.cloudfront.net/mobileMapPoco.webp'
export const mobileMapToronto =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1670263296/nenechicken/mobileMapToronto.jpg'
export const mobilePromotion =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661121429/nenechicken/mobilePromo.jpg'
export const orderPickupButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1663184651/nenechicken/orderOnlineButton2.png'
export const promotions =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661121016/nenechicken/promotions.jpg'
export const viewMenuButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1608578558/samuraisushi/viewMenuButton.png'
export const applyNowButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1661978586/nenechicken/applyButton.png'
export const downloadAppButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1663184651/nenechicken/downloadAppButton.png'

export { default as FraserMenu } from './SamuraiFraserMenu.pdf'
export { default as DavieMenu } from './samuraiDavie2.pdf'

import React from 'react'
import {
  CFView,
  CFImage,
  CFSelect,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  applyNowButton,
  foodGallery1,
  foodGallery2,
  mobileGallery1,
  mobileGallery2,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={mobileGallery1} w="100%" alt="Food Gallery" />
          <a href={`https://forms.gle/WhFLhbZeXMdP4A8d7`}>
            <CFView hover>
              <CFImage
                src={applyNowButton}
                w="100%"
                mt="15px"
                maxWidth="300px"
                alt="About"
              />
            </CFView>
          </a>
          <CFImage src={mobileGallery2} w="100%" alt="Food Gallery" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView column center maxWidth="1400px">
          <CFImage src={foodGallery1} w="100%" alt="Food Gallery" />
          <CFView
            column
            justifyCenter
            alignStart
            w="100%"
            mt="-6.5%"
            mb="30px"
            pl="7%"
          >
            <a href={`https://forms.gle/WhFLhbZeXMdP4A8d7`} target="_blank">
              <CFView hover>
                <CFImage
                  src={applyNowButton}
                  w="25vw"
                  maxWidth="320px"
                  alt="About"
                />
              </CFView>
            </a>
          </CFView>
          <CFImage src={foodGallery2} w="100%" alt="Food Gallery" />
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
